import React from "react"
import moment from 'moment';
import { Link } from 'gatsby'

import ResponsiveImage from '../Image'

const BlogListItem = ({ category, articles }) => {

    const cat_articles = articles.data.filter((item) => {
        return item.attributes.categories && item.attributes.categories.data.find((cat) => {
            return category.attributes.slug === cat.attributes.slug
        })
    })
    if (cat_articles.length < 0) {
        return null
    }
    else {
        return (
            cat_articles.map((item, index) => {
                const { title, slug, cover, shortDescription, readTimeInMinutes, publishedAt } = item.attributes
                console.log('Blog item single', cover)
                return (
                    <div key={index} className="w-100 border-b border-gray-900 grid sm:grid-cols-6 grid-cols-1 lg:gap-16 gap-10 justify-between pt-16 pb-12">
                        <div className="col-span-4">
                            <div className="w-full flex flex-row justify-between">
                                <h6 className="text-base text-gray-400 font-bold tracking-wider">{category.attributes.name}</h6>
                                <div className="flex mb-6">
                                    <p className="text-gray-400 text-sm flex items-center mr-3"><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                    </svg> -{moment(publishedAt).format('Do MMM')}</p>
                                    <p className="text-gray-400 text-sm flex items-center"><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg> -{readTimeInMinutes} min read</p>
                                </div>

                            </div>
                            <h3 className="text-xl font-semibold pb-3"><Link to={`/blog/${slug}`}>{title && title}</Link></h3>
                            <p className="text-base pb-4">{shortDescription && shortDescription}</p>
                            <Link to={`/blog/${slug}`} className="text-gray-800 font-semibold flex items-center">
                                Read more <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 ml-12 mt-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                                </svg>
                            </Link>
                        </div>
                        <div className="col-span-2">
                            {cover?.data?.attributes?.url && (
                                <Link to={`/blog/${slug}`}><ResponsiveImage
                                    large={cover.data.attributes.url}
                                    medium={cover.data.attributes.formats.thumbnail}
                                    small={cover.data.attributes.formats.thumbnail}
                                    width={cover.data.attributes.formats.thumbnail.width}
                                    height={cover.data.attributes.formats.thumbnail.height}
                                />
                                </Link>
                            )}

                        </div>
                    </div>
                )
            })
        )

    }
}
export default BlogListItem