import React from "react"
import { Link } from 'gatsby'
import moment from 'moment';
import ReactMarkdown from "react-markdown";

import { StaticImage } from "gatsby-plugin-image"

import BlogListItem from "./BlogListItem"
import BlogLatestItems from "./BlogLatestItems"


const BlogIndex = ({ data, event }) => {
    const [openTab, setOpenTab] = React.useState(1);
    const { categories, articles } = data
    console.log('event event',event)
    return (
        <>
            <div className="relative">
                <StaticImage src="../../assets/images/blog-hero.jpg" alt="" className="w-full block" />
                {/* <StaticImage src="../../assets/images/blog-hero-mob.jpg" alt="" className="w-full md:hidden block" /> */}
                <div className="max-w-7xl mx-auto px-4">
                    <div className="md:absolute relative lg:bottom-20 md:inset-x-auto right-0 left-0 lg:w-4/12 md:w-6/12 w-full bg-white lg:px-16 sm:px-10 px-8 lg:py-12 py-10">
                        <div className="w-full ">
                            <h6 className="text-xl mb-4 text-gray-400 font-medium">Event Name</h6>
                            <h2 className="text-3xl font-bold mb-4 w-88">{event.name}</h2>
                            <ReactMarkdown className="mb-5 text-lg rmark-content">{event.description.data}</ReactMarkdown>
                            {/* <p className="mb-5 text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p> */}
                            <div className="flex mb-10">
                                <p className="text-gray-400 flex items-center mr-4"><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                </svg> -{moment(event.datetime).format('Do MMM')}</p>
                                <p className="text-gray-400 flex items-center"><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg> -San Francisco</p>
                            </div>
                            <Link to="/contact-us" className=" border-2 font-bold border-blue-600 border-solid text-blue-600 px-10 py-3">
                                Go to booking
                            </Link>
                        </div>
                    </div>
                </div>
            </div>


            <div className="bg-gray-100 md:py-20 py-10">
                <div className="max-w-7xl mx-auto flex lg:flex-row flex-col px-4">
                    <div className="lg:w-1/4 w-full">
                        <h2 className="text-3xl font-bold mb-10">Latest Posts</h2>
                    </div>
                    <div className="lg:w-3/4 w-full">
                        <BlogLatestItems data={articles.data.slice(0, 3)} />
                    </div>
                </div>
            </div>

            <div className="bg-white md:py-20 py-10">
                <div className="max-w-7xl mx-auto flex lg:flex-row flex-col px-4">
                    <div className="lg:w-1/4 w-full">
                        <h2 className="text-3xl font-bold mb-10">All blogs</h2>
                    </div>
                    <div className="lg:w-3/4 w-full">

                        <div className="flex flex-wrap">
                            <div className="w-full">
                                <ul
                                    className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
                                    role="tablist"
                                >
                                    {categories && categories.data && categories.data.map((cat, index) => {
                                        return (
                                            <li className="px-4 py-2 mr-2 mb-2 font-medium text-base border-black border-2" key={index}>
                                                <a
                                                    className={
                                                        " " +
                                                        (openTab === index + 1
                                                            ? "text-base"
                                                            : "text-base")
                                                    }
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        setOpenTab(index + 1);
                                                    }}
                                                    data-toggle="tab"
                                                    href={`#${cat.attributes.slug}`}
                                                    role="tablist"
                                                >
                                                    {cat.attributes.name}
                                                </a>
                                            </li>
                                        )
                                    })}
                                </ul>
                                <div className="w-full pt-5">
                                    {categories && categories.data && categories.data.map((cat, index) => {
                                        return (
                                            <div key={index} className={openTab === index + 1 ? "block" : "hidden"} id={`${cat.attributes.slug}`}>
                                               <BlogListItem category={cat} articles={articles}/>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>

                        <div>


                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
export default BlogIndex;