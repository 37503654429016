import * as React from "react"
import { graphql } from 'gatsby'




import Page from '../../components/Page'

import BlogIndex from "../../components/Blogs/BlogIndex"

const IndexPage = ({data}) => {
  return (
    <>
      <Page page="blog">
        <BlogIndex data={data.strapidata} event={data.EventDetails.nodes[0]}/>
      </Page>
    </>
  )
}

export default IndexPage

export const query = graphql`
query BLogPageQuery {
  EventDetails : allStrapiEvent(limit: 1) {
    nodes {
      name
      datetime
      description {
        data
      }
    }
  }
  strapidata {
    categories {
      data {
        attributes {
          slug
          name
        }
      }
    }
    articles(pagination: {limit: 1000}) {
      data {
        attributes {
          title
          slug
          shortDescription
          readTimeInMinutes
          publishedAt
          cover {
            data {
              id
              attributes {
                url
                width
                height
                formats
              }
            }
          }
          categories {
            data {
              attributes {
                name
                slug
              }
            }
          }
        }
      }
    }
  }
}
`


